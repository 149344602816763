@font-face { 
    font-family: "2-Questa_Grande_Regular"; 
    src: url('../fonts/2-Questa_Grande_Regular.otf') format('opentype'); 
    font-weight: normal; 
}
  
@font-face { 
    font-family: "MoonTime-Regular"; 
    src: url('../fonts/FontsFree-Net-MoonTime-Regular-1.ttf') format('truetype'); 
    font-weight: normal; 
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&family=Ubuntu:wght@300;400&display=swap');

$themeFont: "2-Questa_Grande_Regular";
$funkyFont: "MoonTime-Regular"; 
$font: "Roboto";

$black: #000;
$pink: #FF74CB;
$pinkDropShadow: #FF0092;
$pinkBorder: #FFB4D7;

$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;

*, *:before, *:after {
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

body{
    margin: 0;
    background-color: $black;
    color: #fff;
    font-family: $font;
    font-weight: 300;
}
img{
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}
h2{
    font-family: $funkyFont;
    font-size: 60px;
    font-weight: 300;
    color: $pink;
    text-shadow: 0px 0px 10px $pinkDropShadow;
    margin: 0;
    @media(min-width:$tablet){
        font-size: 70px;
    }
    @media(min-width:$laptop){
        font-size: 80px;
    }
}
h3{
    font-family: $themeFont;
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
}
h4{
    font-family: $font;
    color: $pink;
    font-size: 24px;
    line-height: 26px;
    font-weight: 300;
    text-shadow: 0px 0px 20px $pinkDropShadow;
}
p{
    font-family: $font;
    font-size: 16px;
    line-height: 26px;
}
a{
    text-decoration: none;
}
._grid{
    width: calc(100% - 40px);
    margin: 0 auto;
    max-width: 1400px;
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    ._column{
        width: 100%;
        p{
            max-width: 900px;
            margin-left: auto;
            margin-right: auto;
        }
        h2{
            margin-bottom: 30px;
        }
    }
    &._grid-2{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(50% - 20px);
            }
        }
    }
    &._grid-3{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(33.33% - 30px);
            }
        }
    }
    &._grid-4{
        @media(min-width: $tablet){
            ._column{
                width: calc(50% - 15px);
            }
        }
        @media(min-width: $laptop){
            ._column{
                width: calc(25% - 30px);
            }
        }
    }
    &._grid-3-2{
        @media(min-width: $tablet){
            ._column{
                &:nth-of-type(1){
                    width: calc(66% - 15px);
                }
                &:nth-of-type(2){
                    width: calc(33% - 15px);
                }
            }
        }
        @media(min-width: $laptop){
            ._column{
                &:nth-of-type(1){
                    width: calc(66% - 30px);
                }
                &:nth-of-type(2){
                    width: calc(30% - 30px);
                }
            }
        }
    }
    @media(min-width: $tablet){
        width: calc(100% - 60px);
        column-gap: 25px;
    }
    @media(min-width: $laptop){
        width: calc(100% - 80px);
        column-gap: 30px;
    }
}

section{
    background-position: center;
    background-size: cover;
    position: relative;
    
}

._page-content{
    background-color: $black;
    @media(min-width:$laptop){
        margin-left: 250px;
    }
}

._main-heading{
    line-height: 115px;
    text-align: center;
    span{
        &:nth-of-type(1){
            font-family: $themeFont;
            font-size: 80px;
            line-height: 0px;
            font-weight: 400;
            @media(min-width: $tablet){
                font-size: 125px;
                line-height: 150px;
            }
            @media(min-width: $laptop){
                font-size: 150px;
                line-height: 190px;
            }
        }
        &:nth-of-type(2){
            font-family: $funkyFont;
            font-size: 110px;
            line-height: 50px;
            font-weight: 300;
            color: $pink;
            text-shadow: 0px 0px 10px $pinkDropShadow;
            @media(min-width: $tablet){
                font-size: 150px;
                line-height: 100px;
            }
            @media(min-width: $laptop){
                font-size: 200px;
                line-height: 140px;
            }
        }
    }
}

._button{
    border-radius: 100px;
    background-color: $pink;
    border: 1px solid $pinkBorder;
    padding: 4px 27px;
    font-size: 24px;
    color: #fff;
    box-shadow: 0px 0px 15px $pinkDropShadow;
    font-family: $font;
    font-weight: 300;
}

._pink{
    color: $pink;
    a{
        color: $pink;
    }
}

._section-1{
    height: 65vh;
    position: relative;
    ._panel{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 600px;
        text-align: center;
    }
}

._section-2{
    ._grid{
        align-items: center;
        row-gap: 30px;
        img{
            display: block;
            &:nth-of-type(1){
                margin-bottom: 20px;
                @media(min-width: $laptop){
                    margin-bottom: 30px;
                }
            }
        }
    }
}

._section-3{
    padding: 60px 0;
    p{
        span{
            color: $pinkDropShadow;
        }
    }
}

._section-4{
    text-align: center;
    ._grid{
        row-gap: 30px;
        &:nth-of-type(2){
            padding: 30px 0 0 0;
        }
    }
}

footer{
    text-align: center;
    padding: 60px 0;
    position: relative;
    @media(min-width: $laptop){
        padding: 100px 0;
    }
    ._panel-grid{
        display: flex;
        align-items: center;
        column-gap: 10px;
        justify-content: center;
        margin-top: 60px;
    }
}

._overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.16848746334471287) 25%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.20770314961922265) 75%, rgba(0,0,0,1) 100%); 
    z-index: 0;
}

._overlay2{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgb(0,0,0);
    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box; 
    z-index: 0;
}

._nav{
    position: fixed;
    left: -100px;
    visibility: hidden;
    opacity: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    max-width: 250px;
    z-index: 1;
    background-color: $black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    padding: 30px;
    transition: 0.25s;
    transition-timing-function: ease-in-out;
    &._show{
        visibility: visible;
        opacity: 1;
        left: 0;
    }
    @media(min-width:$laptop){
        visibility: visible;
        opacity: 1;
        left: 0;
    }
    ul{
        padding-left: 0;
        li{
            list-style: none;
            a{
                color: #fff;
                font-family: $themeFont;
                font-size: 24px;
                line-height: 30px;
                font-weight: 300;
                display: block;
                padding: 10px 0;
            }
        }
    }
}

.contact_number{
    font-family: $funkyFont;
    font-size: 80px;
    color: $pink;
    text-shadow: 0px 0px 10px $pinkDropShadow;
    line-height: 10px;
}

._hamburgerButton{
    position: fixed;
    top: 0;
    right: 0;
    margin: 30px;
    z-index: 1;
    button{
        width: 40px;
        height: 40px;
        border: none;
        background-color: transparent;
        position: relative;
        cursor: pointer;
        span{
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #fff;
            left: 0;
            transition: 0.25s;
            transition-timing-function: ease-in-out;
            &:nth-of-type(1){
                top: 13px;
            }
            &:nth-of-type(2){
                bottom: 13px;
            }
        }
        &._close{
            span{
                &:nth-of-type(1){
                    top: 19px;
                    transform: rotate(45deg)
                }
                &:nth-of-type(2){
                    bottom: 19px;
                    transform: rotate(-45deg)
                }
            }
            
        }
    }
    @media(min-width:$laptop){
        display: none;
    }
}